<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-card
            class="mx-auto"
            max-width="1100"
        >
            <v-expansion-panels v-model="panel">
                <v-expansion-panel
                    v-for="(ord,x) in ordenes"
                    :key="x"
                >
                    <v-expansion-panel-header :color="ord.status_id == 'OS_CANCELADA'  ? 'error' : 'primary'" class="white--text">
                        ORDEN DE SERVICIO #{{ord.orden_servicio_id}} {{ ord.valoracion ? '(VALORACIÓN)' :'' }} - COTIZACIÓN {{ord.quote_id}} {{ ord.status_id == 'OS_CANCELADA' ? '(CANCELADA)' : '' }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col>
                            <v-row>
                                <v-col 
                                    cols="12" 
                                    :md="['OS_CREADA','OS_INICIADA'].includes(ord.status_id) && btnCompletarOrden ? 3 : 4" 
                                    sm="6"
                                    class="text-center"
                                >
                                    <v-btn 
                                        color="primary" 
                                        @click="viewDetalles(x)"
                                    >
                                        <v-icon class="mr-2">{{ iconBtn }}</v-icon> {{ textBtn }} DETALLES ORDEN
                                    </v-btn>
                                </v-col>
                                <v-col 
                                    cols="12" 
                                    :md="['OS_CREADA','OS_INICIADA'].includes(ord.status_id) && btnCompletarOrden ? 3 : 4" 
                                    sm="6"
                                    class="text-center"
                                >
                                    <v-btn 
                                        color="success" 
                                        @click="openDialogNuevaOrden(ord)"
                                    >
                                        <v-icon class="mr-2">mdi-calendar-plus</v-icon> GENERAR NUEVA ORDEN
                                    </v-btn>
                                </v-col>
                                <v-col 
                                    cols="12" 
                                    :md="['OS_CREADA','OS_INICIADA'].includes(ord.status_id) && btnCompletarOrden ? 3 : 4" 
                                    class="text-center"
                                    v-if="['OS_CREADA','OS_INICIADA'].includes(ord.status_id)"
                                >
                                    <v-btn 
                                        color="warning" 
                                        @click="cancelarOrden(ord)"
                                    >
                                        <v-icon class="mr-2">mdi-delete-forever</v-icon> CANCELAR LA ORDEN
                                    </v-btn>
                                </v-col>
                                <v-col 
                                    cols="12" 
                                    md="3"
                                    class="text-center"
                                    v-if="['OS_CREADA','OS_INICIADA'].includes(ord.status_id) && btnCompletarOrden"
                                >
                                    <v-btn 
                                        color="error" 
                                        @click="completarOrden(ord)"
                                    >
                                        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon> COMPLETAR LA ORDEN
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-col 
                                cols="12"
                                :id="`div-detalles-orden_${x}`"
                                class="pt-0 d-none"
                            >
                                <v-row>
                                    <v-col col="md-6">
                                        <v-toolbar 
                                            color="green" 
                                            dark 
                                            class="mt-4" 
                                            height="35" 
                                        >
                                            <v-toolbar-title>
                                                <v-icon>mdi-format-list-bulleted</v-icon> Datos de la orden
                                            </v-toolbar-title>
                                        </v-toolbar>
                                        <ul class="mt-3">
                                            <li><b>Número:</b> {{ ord.orden_servicio_id }}</li>
                                            <li><b>Estado:</b> {{ ord.estado }}</li>
                                            <li>
                                                <b>Opotunidad:</b> 
                                                <a 
                                                    target="_blank"
                                                    :href="`/oportunidad_form/${ord.opportunity_id}`"
                                                > 
                                                    {{ ord.opportunity_id}} 
                                                </a>
                                            </li>
                                            <li>
                                                <b>Cotización:</b> 
                                                <a 
                                                    target="_blank" 
                                                    :href="`/crm_cotizacion?sop_id=${ord.opportunity_id}&quote_id=${ord.quote_id}`"
                                                >
                                                    {{ ord.quote_id }}
                                                </a>
                                            </li>
                                            <li><b>Cliente:</b> {{ ord.cliente_nombres }} :: {{ ord.cliente_id }}</li>
                                            <li><b>Paciente:</b> {{ ord.paciente_nombres }} :: {{ ord.paciente_id }}</li>
                                            <li><b>Creado por:</b> {{ ord.created_by_user_login }} </li>
                                        </ul>
                                    </v-col>
                                    <v-col>
                                        <v-toolbar 
                                            color="green" 
                                            dark 
                                            class="mt-4" 
                                            height="35"                                             
                                        >
                                            <v-toolbar-title>
                                                <v-icon>mdi-calendar-multiple-check</v-icon>
                                                Fechas
                                            </v-toolbar-title>
                                        </v-toolbar>
                                        <ul class="mt-3">
                                            <li><b>Fecha de inicio:</b></li>
                                            <li><b>Fecha de finalización:</b></li>
                                        </ul>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-row>
                                <v-col 
                                    md="3"
                                    cols="12"
                                >
                                    <v-col 
                                        cols="12"
                                        calss="text-center"
                                    >
                                        <v-toolbar
                                            color="primary"
                                            dark
                                            dense
                                        >
                                            ESQEMAS
                                        </v-toolbar>
                                        <ul  class="mt-3">
                                            <li  
                                                v-for="(form,x) in ord.formularios"
                                                :key="x"
                                                @click="verFormulariosOrden(form)"
                                                style="cursor: pointer;"
                                            >
                                                {{ form.nombre }}
                                            </li>
                                        </ul>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-toolbar
                                            color="primary"
                                            dark
                                            dense
                                        >
                                            ITEMS DE SERVICIO
                                        </v-toolbar>
                                    </v-col>
                                    <b class="ml-4">{{ ord.items_servicio[0].servicio }}</b>
                                    <v-list-item
                                        v-for="(is, y) in ord.items_servicio"
                                        :key="y"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 13px; white-space: normal;">
                                               {{ is.sub_servicio }} 
                                                <div>
                                                    <v-chip
                                                        :color="`${is.completados == is.cantidad ? 'success' : 'warning'}`"
                                                        text-color="white"
                                                        small
                                                        style="font-size: 13px;"
                                                    >
                                                        {{is.completados}} / {{ is.cantidad }}  {{ is.completados == is.cantidad ? 'Completado' : 'Realizados' }}
                                                    </v-chip>
                                                </div>
                                            </v-list-item-title>
                                            <v-divider class="mt-2"></v-divider>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <p  class="ml-4">
                                        <b>LEYENDA</b>
                                    </p>
                                    <div 
                                        cols="12"
                                        class="ml-4"
                                        v-for="(cs, w) in colorStatus"
                                        :key="`status-${w}`" 
                                    >
                                        <div class="d-flex">
                                            <div :style="`height: 20px;width: 20px;background-color: ${cs.color};`"></div>
                                            <div class="ml-2">{{ cs.status_id.split('_')[2] }}</div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col md="9" cols="12">
                                    <v-col cols="12">
                                        <v-toolbar
                                            color="primary"
                                            dark
                                            dense
                                        >
                                        <div class="d-flex justify-space-between align-center w-100 ">
                                            <div>
                                                CITAS DE SERVICIO
                                            </div>                    
                                            <div>
                                                <v-tooltip 
                                                bottom
                                                v-if="['OS_CREADA','OS_INICIADA'].includes(ord.status_id)"
                                            >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            fab
                                                            x-small
                                                            color="warning"
                                                            style="cursor: pointer"
                                                            @click="agregarCita(ord)"
                                                        >
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Agregar cita</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                        </v-toolbar>
                                        <v-col cols="12" class="mt-3 px-0">
                                            <v-autocomplete
                                                label="Estados del servicio"
                                                :items=statusesCita
                                                v-model=statusCita
                                                dense
                                                outlined
                                                hide-details
                                                item-value="status_id"
                                                item-text="description"
                                                @change="getDataOrdenDeServicio"   
                                            />
                                        </v-col>
                                    </v-col>
                                    <v-col cols="12"  id="div-scroll">                                        
                                        <v-row  v-if="ord.citas.length">                                            
                                            <v-col 
                                                v-for="(cita, z) in ord.citas"
                                                :key="z"
                                                md="6"
                                                cols="12"                                                                                               
                                            >                                                
                                                <div :style="`border: 2px solid ${colorStatus.find(e => e.status_id == cita.status_id).color};border-radius: 10px;padding: 5px;position:relative`">                                                            
                                                    <div 
                                                        v-if="cita.status_id != 'OS_CITA_CANCELADA' && cita.work_effort_id == null"
                                                        class="div-drop text-center"
                                                        style="display: none;cursor:pointer"
                                                        :id="cita.orden_servicio_cita_id" 
                                                        @click="selectSubServicioDestino(cita,ord.citas)"
                                                    >
                                                        <v-icon>mdi-36px mdi-gesture-tap</v-icon> COLOCAR EN LA {{ cita.descripcion.toUpperCase() }}
                                                    </div>    
                                                    <div v-if="cita.editar_nombre_cita">
                                                        <v-text-field
                                                            v-model =cita.descripcion
                                                            label="Nombre cita"
                                                            append-icon="mdi-floppy"
                                                            @click:append="actualizarNombreCita(cita)"
                                                            outlined
                                                            dense
                                                        />
                                                    </div>              
                                                    <div class="pt-3 d-flex justify-space-between align-top">
                                                        <div 
                                                            v-if="!cita.editar_nombre_cita"
                                                            @dblclick="habilitarEdicionNombreCita(ord, cita)"
                                                        >
                                                            <b>{{ cita.descripcion.toUpperCase() }}</b>
                                                        </div>
                                                        <div>
                                                            <!-- <v-tooltip
                                                                bottom
                                                                v-if="cita.work_effort_id == null && cita.status_id == 'OS_CITA_CREADA'"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="primary"
                                                                        class="me-2"                   
                                                                        @click="buscarEvento(cita)"                                                                                                                  
                                                                    >
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Buscar eventos no asignados</span>
                                                            </v-tooltip> -->
                                                            <v-tooltip 
                                                                bottom
                                                                v-if="cita.status_id != 'OS_CITA_CANCELADA'"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="primary"
                                                                        class="me-2"                   
                                                                        @click="verFormulariosCita(cita)"                                                                                                                  
                                                                    >
                                                                        <v-icon>mdi-file-document</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Documentos de la cita</span>
                                                            </v-tooltip>
                                                            <v-tooltip 
                                                                bottom
                                                                v-if="['OS_CITA_CREADA','OS_CITA_INICIADA'].includes(cita.status_id) && cita.work_effort_id == null"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="primary"
                                                                        style="cursor: pointer;"
                                                                        class="me-2"                   
                                                                        @click="agendarEvento(cita)"                                                                                                                  
                                                                    >
                                                                        <v-icon>mdi-calendar-clock</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Agendar evento</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="yellow darken-4"
                                                                        class="me-2 white--text"                   
                                                                        @click="openDialogIncidencia(cita)"                                                                                                                  
                                                                    >
                                                                        <v-icon>mdi-account-alert</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Incidencias</span>
                                                            </v-tooltip>
                                                            <v-tooltip 
                                                                bottom
                                                                v-if="['OS_CITA_CREADA','OS_CITA_INICIADA'].includes(cita.status_id) && cita.work_effort_id == null"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="warning"
                                                                        class="me-2"
                                                                        @click="agregarSubServicio(cita)"
                                                                    >
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Agregar sub servicio</span>
                                                            </v-tooltip>
                                                            <v-tooltip 
                                                                bottom
                                                                v-if="['OS_CITA_CREADA','OS_CITA_INICIADA'].includes(cita.status_id)"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="error"
                                                                        class="me-2"
                                                                        @click="cancelarCita(cita)"
                                                                    >
                                                                        <v-icon>mdi-delete-forever</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Cancelar cita</span>
                                                            </v-tooltip>
                                                            <v-tooltip 
                                                                bottom
                                                                v-if="['OS_CITA_CREADA','OS_CITA_INICIADA'].includes(cita.status_id) && btnCompletarCita"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn 
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        fab
                                                                        x-small
                                                                        color="error"
                                                                        class="me-2"
                                                                        @click="completarCita(cita)"
                                                                    >
                                                                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Completar cita</span>
                                                            </v-tooltip>
                                                        </div> 
                                                    </div>                        
                                                    <div>
                                                        <div>
                                                       
                                                            <div 
                                                                style="font-size: 14px;"
                                                                v-if="cita.work_effort_id != null"
                                                            >
                                                                <div>
                                                                    Evento: <b><a :href="`/evento_form/${cita.work_effort_id}`" target="_blank">#{{ cita.work_effort_id }} </a> </b>
                                                                </div>
                                                                <div>
                                                                    Fecha: <b>{{ cita.estimated_start_date }}</b>
                                                                </div>
                                                                <div>
                                                                    Atiende:
                                                                    <div><b>{{ cita.atiende }}</b></div>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                        
                                                    </div> 
                                                    <v-col
                                                        class="px-1 select-sub-servicio"
                                                        v-for="(css, z) in cita.sub_servicios"                                                        
                                                        :key="z"                                                               
                                                    >   
                                                        <v-select                 
                                                            v-model="css.sub_servicio_crm_id"
                                                            :items="ord.items_servicio"
                                                            hide-details="auto"
                                                            dense
                                                            label="Sub servicios"
                                                            item-text="sub_servicio"
                                                            item-value="sub_servicio_crm_id"
                                                            solo
                                                            :readonly="['OSS_CANCELADO','OSS_COMPLETADO'].includes(css.status_id)"
                                                            :flat="css.status_id == 'OSS_CANCELADO'"
                                                            :error="css.status_id == 'OSS_CANCELADO'"
                                                            @change="guardarSubServicio(css,cita.orden_servicio_id)"
                                                        >
                                                            <template v-slot:prepend>
                                                                <v-icon
                                                                    @click="selectSubServicio(cita.orden_servicio_cita_id, ord.citas, css)"
                                                                    v-if="css.status_id=='OSS_CREADO' && cita.work_effort_id == null"
                                                                >
                                                                    mdi-36px mdi-gesture-tap
                                                                </v-icon>
                                                            </template>
                                                            <template 
                                                                v-slot:prepend-inner
                                                                v-if="!['OSS_CANCELADO','OSS_COMPLETADO'].includes(css.status_id) && cita.work_effort_id != null"
                                                            >
                                                                <v-checkbox
                                                                    v-model="css.approved"
                                                                    @click="completarSubServicio($event,css,cita.orden_servicio_id)"
                                                                    hide-details
                                                                />
                                                            </template>
                                                            <template v-slot:append-outer>
                                                                <div v-if="css.status_id == 'OSS_CANCELADO'"> 
                                                                <v-tooltip  bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            color="error"
                                                                        >
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Sub servicio cancelado</span>
                                                                </v-tooltip>          
                                                                </div>                        
                                                                <div v-else-if="!['OSS_CANCELADO','OSS_COMPLETADO'].includes(css.status_id)">                         
                                                                    <v-tooltip 
                                                                        bottom
                                                                        v-if="css.orden_servicio_cita_sub_servicio_id == null"
                                                                    >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="eliminarSubServicio(css)"
                                                                                color="error"
                                                                            >
                                                                                mdi-delete-forever
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Eliminar sub servicio</span>
                                                                    </v-tooltip>                                                                                                     
                                                                    <v-tooltip 
                                                                        bottom 
                                                                        v-else
                                                                    >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-icon                                                                    
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                color="warning"
                                                                                @click="cancelarSubServicio(css)"
                                                                            > 
                                                                                mdi-cancel
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Cancelar sub servicio</span>
                                                                    </v-tooltip>
                                                                </div>
                                                                <div v-else-if="css.status_id == 'OSS_COMPLETADO'">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                color="success"
                                                                            > 
                                                                                mdi-check
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Sub servicio completado</span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </template>
                                                        </v-select>
                                                    </v-col>                                                                                                                                                                                                    
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-alert
                                            v-else
                                            dense
                                            border="left"
                                            type="warning"
                                        >
                                            No se encontraron citas
                                        </v-alert>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-dialog 
            v-model="dialogAgendarEvento" 
            scrollable 
            persistent 
            :retain-focus="false"
            width="500px"
        >
            <v-card>
                <v-toolbar 
                    color="primary"
                    dark
                    dense
                >
                    Agendar Evento
                    <v-spacer></v-spacer>
                    <v-btn 
                        small 
                        text
                        @click="dialogAgendarEvento=false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <AgendamientoComponent
                    v-if="dialogAgendarEvento" 
                    :partyIdClient= partyIdClient
                    :oportunidadId = oportunidadId
                    :externalServiceCrmId= serviceCrmId
                    :externalSubServiceCrmId = subServiceCrmId
                    :ordenServicioCitaId = ordenServicioCitaId
                    @close="closeDialogAgendarEvento"
                />
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogEventoNoAsignado" 
            scrollable 
            persistent 
            :retain-focus="false"
            width="500px"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    dark 
                    color="primary" 
                    height="40" 
                    flat
                >
                      EVENTOS DISPONIBLES
                </v-toolbar>
                <div v-if="eventosNoAsignados.length">
                    <v-list-item
                        v-for="(ena, y) in eventosNoAsignados"
                        :key="y"
                    >
                        <v-list-item-content>
                            <div class="d-flex justify-space-between align-center">
                                <div>
                                    <div style="font-size: 13px; white-space: normal">
                                        SERVICIO = <b>{{ena.work_effort_name}}</b>               
                                    </div>
                                    <div style="font-size: 13px; white-space: normal">
                                        EVENTO = <b>{{ ena.work_effort_id }}</b>          
                                    </div>
                                    <div style="font-size: 13px; white-space: normal">
                                        FECHA = {{ ena.estimated_start_date }}
                                    </div>
                                    
                                    <div style="font-size: 13px; white-space: normal">
                                        OPORTUNIDAD = <b>{{ena.sales_opportunity_id}}</b>                         
                                    </div>
                                </div>
                                <div>
                                    <v-btn
                                        small
                                        color="primary"
                                        @click="asignarEventoCita(ena)"
                                    >
                                        ASIGNAR
                                    </v-btn>
                                </div>
                                
                            </div>

                            <v-divider class="mt-2"></v-divider>
                        </v-list-item-content>                    
                    </v-list-item>  
                </div>
                <div 
                    v-else
                    class="my-2 mx-2"
                >
                    <v-alert                                            
                        dense
                        border="left"
                        type="warning"
                    >
                        No se encontraron formularios
                    </v-alert>
                </div>                            
                <v-card-actions>
                    <v-col cols="12" class="text-center">                        
                        <v-btn
                            class="mx-1"
                            color="default"
                            @click="dialogEventoNoAsignado= false"
                        >
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogNuevaOrdenServicio" 
            scrollable 
            persistent 
            :retain-focus="false"
            width="800px"
        >
            <v-card>                
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    dark 
                    color="primary" 
                    height="40" 
                    flat
                >
                    NUEVA ORDEN DE SERVICIO
                </v-toolbar>                
                <v-card-text>
                    <v-col cols="12">
                        <b> <v-icon>mdi-account-star</v-icon> CLIENTE:</b> {{ clienteNuevaOrden }}
                    </v-col>
                    <v-col cols="12">
                        <b> <v-icon>mdi-medical-bag</v-icon> PACIENTE:</b> {{ pacienteNuevaOrden }}
                    </v-col>
                    <v-col cols="12">
                        <v-toolbar 
                            dark 
                            color="primary" 
                            height="40" 
                            flat
                        >
                            PRODUCTOS
                            <v-divider></v-divider>
                            <v-btn 
                                small 
                                text
                                fab
                                @click="addProducto"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-col cols="12">                                                        
                            <v-list-item
                                v-for="(prod, x) in productos"
                                :key="x"
                                class="px-0"
                            >                                
                                    <v-list-item-action class="mr-2">
                                        <v-text-field
                                            label="Cantidad"
                                            type="number"
                                            class="text-center"
                                            v-model.number="prod.cantidad"
                                            dense
                                            outlined
                                            hide-details
                                            style="width:100px"
                                        >
                                        </v-text-field>
                                    </v-list-item-action>
                                    <v-list-item-action 
                                        class="mr-2 valoracion"
                                        v-if="prod.requiere_valoracion"
                                    >
                                        <v-checkbox
                                            v-model="prod.valoracion"
                                            label="VALORACIÓN"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-combobox                                
                                            v-model="prod.productId"
                                            :search-input.sync="prod.search_product"
                                            :items="prod.productos"
                                            label="PRODUCTOS"
                                            :id="`producto-${x+1}`"
                                            :name="`producto-${x+1}`"
                                            :key="`producto-${x+1}`"
                                            item-text="internalName"
                                            item-value="productId"
                                            type="search"
                                            outlined
                                            dense
                                            hide-details
                                            @keydown.enter="getProductos(prod)"
                                            clearable
                                            @change="setInputValoracion(prod)"
                                        >
                                            <template v-slot:append-outer>
                                                <v-icon
                                                    color="red"
                                                    @click="deleteProducto(prod)"
                                                >
                                                    mdi-delete-forever
                                                </v-icon>
                                            </template>      
                                        </v-combobox>
                                    </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-col>
                    <v-col cols="12">
                        <v-toolbar 
                            dark 
                            color="primary" 
                            height="40" 
                            flat
                        > 
                            DATOS DE LA NUEVA ORDEN {{nuevoPaciente ? '(NUEVO)' : ''}}
                        </v-toolbar>                            
                        <v-col cols="12">
                            <v-combobox                                
                                v-model="partyIdPaciente"
                                :search-input.sync="searchPacienteList"
                                :items="pacientes"
                                label="PACIENTE (PARA LA NUEVA ORDEN)"
                                item-text="nombre"
                                item-value="party_id"
                                outlined
                                type="search"
                                id="persona"
                                name="persona"
                                key="persona"
                                dense
                                hide-details="auto"
                                @keydown.enter="getPaciente('PACIENTE')"
                                clearable
                            />
                            
                        </v-col>
                        <v-form 
                            ref="form_nuevo_paciente"
                            v-if="nuevoPaciente"
                        >
                            <v-col cols="12" class="px-0">
                                <v-row>
                                    <v-col cols=12 md="6">
                                        <v-text-field 
                                            v-model="firstNamePaciente" 
                                            dense 
                                            label="Nombres"
                                            :rules=required
                                        />
                                    </v-col>
                                    <v-col cols=12 md="6">
                                        <v-text-field 
                                            v-model="lastNamePaciente" 
                                            dense 
                                            label="Apellidos"
                                            :rules=required
                                        />
                                    </v-col>
                                </v-row>    
                                <v-row>
                                    <v-col cols=12>
                                        <v-checkbox
                                            v-model="noValidaIdentificacion"
                                            label="Identificación (NO APLICA)"
                                            color="primary"
                                            hide-details
                                            class="font-weight-bold mt-0"                     
                                        />
                                    </v-col>
                                </v-row>       
                                <v-row>
                                    <v-col cols=12 md="6">
                                        <v-select
                                            :rules="noValidaIdentificacion ? [] : required"
                                            label="Tipo de identificación"
                                            :items="partyIdentificationTypes"
                                            item-text="description"
                                            item-value="party_identification_type_id"
                                            v-model="partyIdentificationTypeIdPaciente"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols=12 md="6" style="margin-top: 19px;">
                                        <v-text-field 
                                            v-model="identificacionPaciente" 
                                            dense 
                                            label="Identificación"
                                            :rules="noValidaIdentificacion ? [] : required"
                                        />
                                    </v-col>
                                </v-row>                                    
                            </v-col>
                        </v-form>                     
                    </v-col>
                </v-card-text>  
                <v-card-text>
                    <em class="red--text subtitle-1"> <b>Nota: se creará una nueva cotización y orden de servicio con los items seleccionados</b> </em>
                </v-card-text>            
                <v-card-actions>
                    <v-col cols="12" class="text-center">      
                        <v-btn
                            class="mx-1"
                            color="primary"
                            @click="guardarNuevaOrden"
                        >
                            <v-icon>mdi-floppy</v-icon> GUARDAR
                        </v-btn>                  
                        <v-btn
                            class="mx-1"
                            color="default"
                            @click="closeDialogNuevaOrdenServicio"
                        >
                        <v-icon>mdi-cancel</v-icon> Cerrar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogFormulariosOrden" 
            scrollable 
            persistent 
            width="700px"
            :retain-focus="false"            
            :no-click-animation="true"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    dark 
                    color="primary" 
                    height="40" 
                    flat
                >
                    <v-icon>mdi-file-multiple</v-icon> DOCUMENTOS DE LA ORDEN
                </v-toolbar>
                <div v-if="formularios.length">
                    <v-list three-line>
                        <template v-for="(form, x) in formularios">
                            <v-divider
                                v-if="(x+1) == formularios.lenght"
                                :key="`div-${form.formulario_servicio_crm_id}`"
                                inset
                            />
                            <v-list-item :key="`list-${form.sub_servicio_crm_id}`">
                                <v-list-item-content>
                                    <v-list-item-title>                                    
                                        <b style="text-transform: capitalize;">
                                            {{ `Servicio: ${form.servicio}` }}
                                        </b>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-html="form.nombre" />
                                    <v-btn
                                        small
                                        color="success"
                                        @click="seleccionarFormulario(form)"
                                    >
                                        <v-icon>mdi-floppy</v-icon> Crear formulario
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </div>
                <div 
                    v-else
                    class="my-2 mx-2"
                >
                    <v-alert                                            
                        dense
                        border="left"
                        type="warning"
                    >
                        No se encontraron formularios
                    </v-alert>
                </div>       
                <v-card-actions style="background-color: #f0f0f0;">
                    <v-col cols="12" class="text-center">                        
                        <v-btn
                            class="mx-1"
                            color="default"
                            @click="closeDialogFormulariosOrden"
                        >
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogFormulariosCita" 
            scrollable 
            persistent 
            width="700px"
            :retain-focus="false"            
            :no-click-animation="true"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    dark 
                    color="primary" 
                    height="40" 
                    flat
                >
                    <v-icon>mdi-file-multiple</v-icon> DOCUMENTOS DE LA CITA
                </v-toolbar>

                <v-tabs v-model=tab grow>
                    <v-tab>
                        LLENAR FORMULARIOS
                    </v-tab>
                    <v-tab
                        @click="verFormulariosCita(citaSelected)"
                    >
                        FORMULARIOS GUARDADOS
                    </v-tab>
                    <v-tab
                        @click="verImagenesCita(citaSelected)"
                    >
                       CARGAR IMÁGENES
                    </v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item class='mt-6'>
                            <div v-if="formularios.length">
                                <v-list three-line>
                                    <template v-for="(form, x) in formularios">
                                        <v-divider
                                            v-if="(x+1) == formularios.lenght"
                                            :key="`div-${form.formulario_servicio_crm_id}`"
                                            inset
                                        />
                                        <v-list-item :key="`list-${form.sub_servicio_crm_id}`">
                                            <v-list-item-content>
                                                <v-list-item-title>                                    
                                                    <b style="text-transform: capitalize;">
                                                        {{ 
                                                            form.sub_servicio_crm_id != null 
                                                            ?
                                                                `Sub servicio: ${(ordenes.find(e => e.orden_servicio_id == ordenServicioIdSelected)
                                                                    ?.citas.find(e => e.orden_servicio_cita_id == ordenServicioCitaIdSelected)
                                                                    ?.sub_servicios.find(e => e.sub_servicio_crm_id == form.sub_servicio_crm_id).sub_servicio)}`   
                                                            : 
                                                                `Servicio: ${ordenes.find(e => e.orden_servicio_id == ordenServicioIdSelected)?.items_servicio[0].servicio}`
                                                        }}
                                                    </b>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-html="form.nombre" />
                                                <v-btn
                                                    small
                                                    color="success"
                                                    @click="seleccionarFormulario(form)"
                                                >
                                                    <v-icon>mdi-floppy</v-icon> Crear formulario
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>
                            <div 
                                v-else
                                class="my-2 mx-2"
                            >
                                <v-alert                                            
                                    dense
                                    border="left"
                                    type="warning"
                                >
                                    No se encontraron formularios
                                </v-alert>
                            </div>                            
                        </v-tab-item>
                        <v-tab-item class='mt-6'>
                            <div v-if="formularios.length">
                                <v-list three-line>
                                    <template v-for="(fg, nombre) in formulariosGuardados">                           
                                        <v-list-item :key="`list-${nombre}`">
                                            <v-list-item-content class="text-center">
                                                <v-list-item-title>                                    
                                                    <b style="text-transform: capitalize;">
                                                        {{ nombre.split('-')[0] }}
                                                    </b>
                                                    
                                                </v-list-item-title>
                                                <v-col cols="12"  class="text-center">                                             
                                                    <v-row>
                                                        <v-col 
                                                            cols="12" 
                                                            v-for="(form, x) in formulariosGuardados[nombre]"        
                                                            :key="x"     
                                                        >                              
                                                            <v-col class="pt-0"> {{ form.formulario }} </v-col>       
                                                            <v-card class="rounded-xl grey lighten-5">                                                                                
                                                                <div class="d-flex align-center justify-center py-2" style="grid-gap:30px">
                                                                    <div>
                                                                        <v-icon 
                                                                            color="success"
                                                                            @click="verFormularioGuardado(form)"
                                                                        >
                                                                            mdi-48px mdi-file-check
                                                                        </v-icon>     
                                                                        <div>{{ form.created_by_user }} - {{ moment(form.created_stamp).format('YYYY-MM-DD HH:mm:ss') }}</div>  
                                                                    </div>
                                                                    <div>
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn
                                                                                    color="warning"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                    fab
                                                                                    small
                                                                                    @click="verFormularioGuardado(form,false,true)"
                                                                                >
                                                                                    <v-icon> mdi-pencil </v-icon>
                                                                                </v-btn>                                                                           
                                                                            </template>
                                                                            <span>Editar formulario</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn
                                                                                    color="error"
                                                                                    v-bind="attrs"
                                                                                    v-on="on" 
                                                                                    fab
                                                                                    class="ml-2"
                                                                                    small
                                                                                    @click="cancelarFormulario(form)"
                                                                                >                                                                        
                                                                                    <v-icon> mdi-delete-forever </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>Cancelar formulario</span>
                                                                        </v-tooltip>    
                                                                    </div>
                                                                </div>         
                                                            </v-card>                                          
                                                        </v-col>
                                                    </v-row>
                                                </v-col>                                                
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>                            
                        </v-tab-item>
                        <v-tab-item class='mt-0'>
                            <div>
                                <v-list three-line>
                                    <v-list-item>
                                        <v-col cols="12">
                                            <v-col class="seccion-img">
                                                <v-list three-line>
                                                    <template v-for="(ss, x) in ordenes[0]?.citas.find(e => e.orden_servicio_cita_id == ordenServicioCitaIdSelected)?.sub_servicios">
                                                        <v-list-item :key="`ss-imagenes-${ss.sub_servicio_crm_id}`">
                                                            <v-list-item-content class="px-0">
                                                                <v-list-item-title class="px-0"> 
                                                                    <v-alert
                                                                        dense
                                                                        border="left"
                                                                        type="info"
                                                                    >                               
                                                                        <b style="text-transform: capitalize;">
                                                                            {{ `Sub servicio: ${(ordenes.find(e => e.orden_servicio_id == ordenServicioIdSelected)
                                                                                ?.citas.find(e => e.orden_servicio_cita_id == ordenServicioCitaIdSelected)
                                                                                ?.sub_servicios.find(e => e.sub_servicio_crm_id == ss.sub_servicio_crm_id).sub_servicio)}`   
                                                                            }}
                                                                        </b>
                                                                    </v-alert> 
                                                                </v-list-item-title>
                                                                <v-form :ref="`form-files-${x}`">
                                                                    <v-col cols="12">
                                                                        <v-file-input
                                                                            label=".jpg .png .jpeg"
                                                                            hide-details="auto"
                                                                            outlined
                                                                            dense
                                                                            accept=".jpg,.png,.jpeg"
                                                                            multiple
                                                                            :rules="required"
                                                                            @change="setFile"
                                                                            class="btn-upload-img"
                                                                        >
                                                                            <template v-slot:append-outer>
                                                                                <v-tooltip bottom >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-btn
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            color="success"
                                                                                            small
                                                                                            fab
                                                                                            @click="guardarImagenesCita(ss.orden_servicio_cita_sub_servicio_id)"
                                                                                        >
                                                                                            <v-icon>mdi-floppy</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Guardar archivo</span>
                                                                                </v-tooltip>
                                                                            </template>

                                                                        </v-file-input>
                                                                    </v-col>
                                                                </v-form>
                                                                <v-col cols="12">
                                                                <v-row>
                                                                    <v-col
                                                                        v-for="(ic, y) in imagenesCargadas.filter(e => e.orden_servicio_cita_sub_servicio_id == ss.orden_servicio_cita_sub_servicio_id)"
                                                                        :key="`${x}-${y}`"
                                                                        md="4"
                                                                    >   
                                                                        <div class="text-center">
                                                                            <v-btn
                                                                                small
                                                                                color="error"
                                                                                class="mb-1"
                                                                                @click="eliminarImagenCita(ic)"
                                                                            >
                                                                                <v-icon>mdi-delete-forever</v-icon> ELIMINAR
                                                                            </v-btn>
                                                                        </div>
                                                                        <div class="my-1">
                                                                            <v-text-field
                                                                                v-model="ic.nombre"
                                                                                label="Nombre"
                                                                                outlined
                                                                                dense
                                                                                hide-details
                                                                           >
                                                                                <template v-slot:append-outer>
                                                                                    <v-icon 
                                                                                        color="success"
                                                                                        @click="updateNombreArchivoMultimedia(ic)"
                                                                                    >
                                                                                        mdi-floppy
                                                                                    </v-icon>
                                                                                </template>
                                                                            </v-text-field>
                                                                        </div>
                                                                        <a
                                                                            target="_blank" 
                                                                            :href="`${urlApi}img/formularios/${ic.document}`"
                                                                        >
                                                                            <v-img
                                                                                class="border-img rounded-lg"
                                                                                max-height="250"
                                                                                max-width="250"
                                                                                :src="`${urlApi}img/formularios/${ic.document}`"
                                                                            />
                                                                        </a>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-list>
                                            </v-col>
                                        </v-col>
                                    </v-list-item>
                                </v-list>
                            </div>                            
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>     
                <v-card-actions style="background-color: #f0f0f0;">
                    <v-col cols="12" class="text-center">                        
                        <v-btn
                            class="mx-1"
                            color="default"
                            @click="closeDialogFormulario"
                        >
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogVisualizaFormulario"              
            persistent
            fullscreen 
            :retain-focus="false"            
            :no-click-animation="true"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <VisualizaFormularioComponent 
                    v-if="dialogVisualizaFormulario"
                    :formulario=formularioSeleccionado
                    :nuevo = nuevoFormulario
                    :editar= editarFormulario
                    :ordenServicioId = this.$route.params.orderId
                    @close="closeDialogVisualizaFormulario"
                    @store="storeFormulario"
                />
            </v-card>          
        </v-dialog>
        <v-dialog 
            v-model="dialogIncidencia"              
            persistent
            width="600px" 
            :retain-focus="false"            
            :no-click-animation="true"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    dark 
                    color="primary" 
                    height="40" 
                    flat
                >
                    <v-icon>mdi-file-multiple</v-icon> INCIDENCIAS DE LA CITA
                </v-toolbar>
                <v-tabs v-model=tab grow>
                    <v-tab>
                        CREAR NUEVA INCIDENCIA
                    </v-tab>
                    <v-tab
                        @click="verIncidenciasCita"
                    >
                        INCIDENCIAS GUARDADAS
                    </v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-form ref="form-files-incidencia">
                                <v-col cols="12">
                                    <v-card-title class="px-0 pt-0"> 
                                        <v-icon>mdi-image</v-icon> CARGAR IMÁGENES
                                    </v-card-title>
                                    <v-file-input
                                        label=".jpg .png .jpeg"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        accept=".jpg,.png,.jpeg"
                                        multiple
                                        @change="setFileIncidencia"
                                        class="btn-upload-img"
                                        prepend-icon=""
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="comentarioIncidencia"
                                        :rules="required"
                                        outlined
                                        name="input-7-4"
                                        label="Comentario"
                                    />
                                </v-col>
                                <v-card-actions style="background-color: #f0f0f0;">
                                    <v-col cols="12" class="text-center">     
                                        <v-btn
                                            class="mx-1"
                                            color="success"
                                            @click="guardarIncidencia"
                                        >
                                            GUARDAR
                                        </v-btn>                   
                                        <v-btn
                                            class="mx-1"
                                            color="default"
                                            @click="closeDialogIncidencia"
                                        >
                                            CERRAR
                                        </v-btn>
                                    </v-col>
                                </v-card-actions>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item class='mt-6'>
                            <v-col cols="12">
                                <v-timeline
                                    reverse
                                    dense
                                >
                                    <v-timeline-item
                                        v-for="(ic, x) in incidenciasCreadas"
                                        :key="x"
                                    >
                                        <v-card class="elevation-2">
                                            <v-card-title class="text-h5">
                                                {{ ic.created_by_user }} 
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ ic.created_stamp }}
                                            </v-card-subtitle>
                                            <v-card-text>
                                                {{ ic.descripcion }}
                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                                <v-card-actions style="background-color: #f0f0f0;">
                                    <v-col cols="12" class="text-center">       
                                        <v-btn
                                            class="mx-1"
                                            color="default"
                                            @click="closeDialogIncidencia"
                                        >
                                            CERRAR
                                        </v-btn>
                                    </v-col>
                                </v-card-actions>
                            </v-col>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card>          
        </v-dialog>
    </div>
</template>

<script>

import Vue from 'vue';
import { mapActions, mapState, mapMutations } from "vuex"
import AgendamientoComponent from './AgendamientoComponent.vue'
import VisualizaFormularioComponent from './VisualizaFormularioComponent.vue'
import moment from 'moment'
import axios from 'axios';

export default {
    name: 'GeneraOrdenServicioComponent',
    components:{
        AgendamientoComponent,
        VisualizaFormularioComponent
    },
    data: () => ({
        overlay: false,
        ordenes:[],
        statusesCita:[],
        eventosNoAsignados:[],
        servicios:[],
        subServicios:[],
        pacientes:[],
        formularios:[],
        partyIdentificationTypes:[],
        subServiceCrmId: [],
        formulariosGuardados:[],
        files:[],
        filesIncidencia:[],
        imagenesCargadas:[],
        incidenciasCreadas:[],
        tab:null,
        productos:[{
            productos:[],
            search_product:null,
            productId:null,
            valoracion:false,
            requiere_valoracion:false,
            cantidad:1,
            uuid: Math.random().toString(36).substr(0, 20)
        }],
        servicioSelectedId:null,
        panel: 0,
        statusCita: '',
        dialogAgendarEvento:false,
        dialogFormulariosCita:false,
        dialogFormulariosOrden:false,
        dialogVisualizaFormulario:false,
        dialogEventoNoAsignado:false,
        dialogIncidencia:false,
        firstNamePaciente:null,
        lastNamePaciente:null,
        partyIdentificationTypeIdPaciente:null,
        identificacionPaciente:null,
        noValidaIdentificacion:false,
        formularioServicioCrmId:null,
        comentarioIncidencia:null,
        searchPacienteList:"",
        formularioSeleccionado: {},
        citaSelected:{},
        colorStatus:[
            {status_id:'OS_CITA_CREADA', color:'#ededed'},
            {status_id:'OS_CITA_INICIADA', color:'#f6921e'},
            {status_id:'OS_CITA_COMPLETADA', color:'#37b34a'},
            {status_id:'OS_CITA_CANCELADA', color:'#ff5252'},
        ],
        serviceCrmId: null,        
        partyIdClient:null,
        oportunidadId:null,
        oscIdTouch:null,
        partyIdPaciente:null,
        ordenServicioCitaId:null,
        ordenServicioCitaSubServicioIdCopiar:null,
        subServicioIdCopiar:null,
        asignaOrdenServicioCitaId : null,
        lastScroll:0,
        scrollStop:false,
        dialogNuevaOrdenServicio:false,
        nuevoPaciente:false,
        partyIdClienteNuevaOrden:null,
        originalServicioId:null,
        originalOrdenServicioId:null,
        clienteNuevaOrden:null,
        pacienteNuevaOrden:null,
        productStoreIdNuevaOrden:null,
        pacienteIdNuevaOrden:null,
        opportunityIdNuevaOrden:null,
        ordenServicioCitaIdSelected : null,
        ordenServicioIdSelected : null,
        formularioServicioCrmIdSelected:null,
        servicioCrmIdSelected:null,
        nuevoFormulario:false,
        editarFormulario:false,
        formIdEditar:null,
        opcionesNoAgedar:{},
        iconBtn:'mdi-eye',        
        textBtn: 'VER',
        moment:moment,
        required:[
            v => !!v || 'El campo es requerido'
        ],
        setCheckbox: function (id){

            document.getElementById(id).checked = false

        }
    }),
    watch:{

        servicioSelectedId: function(val){        

            this.subServicios = val != null ? this.servicios.find(e => e.servicio_crm_id == val).sub_servicios : []

        }

    },
    computed: {

        ...mapState("master", ["loadingTable","tenantId","paramAlertQuestion","titleToolbar","token","url","user","urlApi"]),

        ...mapState("access", ["idActionsRol"]),

        btnCompletarOrden: function(){
            
            return this.idActionsRol.some(e => e == 'BTN_COMP_ORDEN_SERV')

        },
        
        btnCompletarCita: function(){

            return this.idActionsRol.some(e => e == 'BTN_COMP_CITA_ORDEN')

        },

        notaNuevaOrden: function(){

            let texto = ''

            if(this.ordenes.length && this.servicioSelectedId != null){

                console.log(this.ordenes[0].items_servicio[0].servicio_crm_id, this.servicioSelectedId)

                if(this.ordenes[0].items_servicio[0].servicio_crm_id == this.servicioSelectedId){

                    texto = 'Los sub servicios seleccionados se anexaran a una nueva cita en la misma orden de servicio'

                }else{

                    texto = 'Los sub servicios seleccionados se crearan en una nueva orden de servicio'
                }
                    

            }
            
            return texto

        }

    },
    methods:{

        ...mapMutations("master", ["setUrl", "setLoadingTable", "setTitleToolbar"]),

        ...mapActions("master", ["requestApi", "alertNotification","errorRequest"]),

        getDataOrdenDeServicio(){

            this.overlay = true

            this.setUrl(`orden-servicio/${this.$route.params.quoteId}`)
            
            this.requestApi({
                method: "GET",
                data: {
                    quoteId: this.$route.params.quoteId,
                    pacienteId : this.$route.params.pacienteId,
                    statusCita: this.statusCita,
                    typeView: 'OBTENER_ORDEN_COTIZACION'
                },
            }).then(res => {
                console.log(res)
                if(!res.data.success){

                    this.alertNotification({
                        param:{
                            title: 'Error',
                            icon: 'mdi-cancel',
                            confirmButtonColor: 'red',
                            html: res.data.msg
                        }
                    })

                }else{
                    
                    try {
                        
                        if(typeof this.$route.params.orderId != 'undefined' && typeof this.$route.params.pacienteId == 'undefined'){

                            this.ordenes = res.data.data.ordenesServicio.filter(e => e.orden_servicio_id == this.$route.params.orderId)

                            if(!this.ordenes.length){

                                this.alertNotification({
                                    param:{
                                        title: 'Error',
                                        icon: 'mdi-cancel',
                                        confirmButtonColor: 'red',
                                        html: `No existe la orden #${ this.$route.params.orderId}`
                                    }
                                })

                            }

                        }else{

                            if(typeof this.$route.params.orderId != 'undefined'){

                                let primeraOrden  = res.data.data.ordenesServicio.find(e => e.orden_servicio_id == this.$route.params.orderId)
                                this.ordenes.push(primeraOrden)

                                let otrasOrdenes = res.data.data.ordenesServicio.filter(e => e.orden_servicio_id != this.$route.params.orderId)
                                this.ordenes.push(...otrasOrdenes)

                            }else{

                                this.ordenes = res.data.data.ordenesServicio

                            }

                        }

                        this.statusesCita = res.data.data.status
                        this.opcionesNoAgedar = res.data.data.motivos_no_agendar

                        this.statusesCita.unshift({
                            'status_id' : '',
                            'description': 'Todos'
                        })
                    } catch (error) {

                        console.log(error)

                    }

                    console.log('status',this.statusesCita)
                }

                this.overlay = false

            }).catch(err => {

                this.overlay = false

            })
    
        },

        agregarCita(ord){

            Vue.swal({
                html: 'Está seguro agregar otra cita a la orden de servicio?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioId: ord.orden_servicio_id,
                            typeStore:'agregarCita'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                       
                    }).catch(err => {

                        this.overlay = false

                    })
                    
                }

            })

        },

        cancelarCita(cita){

            Vue.swal({
                html: 'Está seguro agregar cancelar la cita?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                input: "textarea",
                inputPlaceholder: 'Escriba un comentario',
                ...this.paramAlertQuestion,
                inputValidator: (value) => {

                    return new Promise((resolve) => {
                        if (value == "") {
                            
                            resolve("Debe escribir un comentario para cancelar")

                        } else {

                            resolve()
                            
                        }

                    })
                }
            }).then((result) => {

                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioCitaId: cita.orden_servicio_cita_id,
                            comentario: result.value,
                            typeStore:'cancelarCita'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                    }).catch(err => {

                        this.overlay = false

                    })
                    
                }

            })

        },

        agregarSubServicio(cita){
               
            let os = this.ordenes.find(e => e.orden_servicio_id == cita.orden_servicio_id)

            let indexServ = this.ordenes.indexOf(os)

            let osc = this.ordenes[indexServ].citas.find(e => e.orden_servicio_cita_id == cita.orden_servicio_cita_id)

            let indexOsc = this.ordenes[indexServ].citas.indexOf(osc)

            this.ordenes[indexServ].citas[indexOsc].sub_servicios.push({
                status_id: 'OSS_CREADO',
                orden_servicio_id: cita.orden_servicio_id,
                orden_servicio_cita_id: cita.orden_servicio_cita_id,
                orden_servicio_cita_sub_servicio_id: null,
                sub_servicio_crm_id: null,
                sub_servicio: '',
                valoracion: false,
                indexNew :  Math.random().toString(36)
            })

        },

        eliminarSubServicio(ss){

            let os = this.ordenes.find(e => e.orden_servicio_id == ss.orden_servicio_id)

            let indexServ = this.ordenes.indexOf(os)

            let osc = this.ordenes[indexServ].citas.find(e => e.orden_servicio_cita_id == ss.orden_servicio_cita_id)

            let indexOsc = this.ordenes[indexServ].citas.indexOf(osc)

            let css = this.ordenes[indexServ].citas[indexOsc].sub_servicios.find(e => e.indexNew == ss.indexNew)

            let indexCss = this.ordenes[indexServ].citas[indexOsc].sub_servicios.indexOf(css)
         
            this.ordenes[indexServ].citas[indexOsc].sub_servicios.splice(indexCss,1)
        },

        guardarSubServicio(ss,orden_servicio_id, dragAndDrop = false){
           
            return new Promise((resolve, reject)=>{

                this.overlay = true
                
                this.setUrl('orden-servicio')

                this.requestApi({
                    method: "POST",
                    data: {
                        ordenServicioCitaSubServicioId: ss.orden_servicio_cita_sub_servicio_id,
                        ordenServicioCitaId: ss.orden_servicio_cita_id,
                        subServicioCrmId: ss.sub_servicio_crm_id,
                        ordenServicioId : orden_servicio_id,
                        typeStore: 'agregarSubServicioCita',
                        dragAndDrop
                    },
                }).then(res => {

                    try {
                        
                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                        resolve()

                    } catch (error) {

                        this.overlay = false
                        console.log('error',error)
                        reject()

                    }

                }).catch(err => {

                    this.overlay = false
                    reject()

                })

            })
                    
        },

        cancelarSubServicio(ss){

            Vue.swal({
                html : 'Está seguro de cancelar el sub servicio?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                input: "textarea",
                inputPlaceholder: 'Escriba un comentario',
                ...this.paramAlertQuestion,
                inputValidator: (value) => {

                    return new Promise((resolve) => {

                        if (value == "") {
                            
                            resolve("Debe escribir un comentario para cancelar")

                        } else {

                            resolve()
                            
                        }

                    })

                }
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl('orden-servicio')

                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioCitaSubServicioId: ss.orden_servicio_cita_sub_servicio_id,
                            comentario: result.value,
                            typeStore: 'cancelarSubServicioCita'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                    }).catch(err => {

                        this.overlay = false

                    })

                }

            })

        },

        agendarEvento(cita){
            
            console.log('cita',cita)

            this.serviceCrmId = cita.servicio_id

            this.oportunidadId = cita.opportunity_id
       
            this.subServiceCrmId = cita.sub_servicios.filter(e => e.status_id != 'OSS_CANCELADO' && e.orden_servicio_cita_sub_servicio_id != null).map(obj => { return obj.sub_servicio_crm_id })

            this.partyIdClient = cita.paciente_id

            this.ordenServicioCitaId = cita.orden_servicio_cita_id

            this.dialogAgendarEvento = true

        },

        closeDialogAgendarEvento(){

            this.getDataOrdenDeServicio()

            this.dialogAgendarEvento=false

        },

        completarSubServicio(event,ss,orden_servicio_id){
           
            event.preventDefault()
            event.stopPropagation()

            let orden = this.ordenes.find(e => e.orden_servicio_id == orden_servicio_id)
  
            if(typeof orden != 'undefined'){

                let cita = orden.citas.find(e => e.orden_servicio_cita_id == ss.orden_servicio_cita_id)

                let citasActivas = orden.citas.filter(e => e.status_id != 'OS_CITA_CANCELADA' && e.work_effort_id == null)

                let indexCita = citasActivas.indexOf(cita)

                let sigCita = citasActivas[indexCita+1]

                let ultimoSubServicio = cita.sub_servicios.filter(e => e.status_id == 'OSS_CREADO').length == 1

                //PREGUNTA SI QUIERE AGENDAR LA SIGUIENTE CITA
               
                if(typeof sigCita != 'undefined'){

                    if(ultimoSubServicio){

                        Vue.swal({
                            html: `Desea agendar la siguiente cita? <p> <b>${sigCita.descripcion.toUpperCase()}</b> </p>`,
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "SI",
                            cancelButtonText: "NO",
                            cancelButtonColor: "#d33",
                            allowOutsideClick:false
                        })
                        .then(result => {

                            if (result.isConfirmed) {

                                ss.approved = false

                                this.agendarEvento(sigCita)

                                this.accionCompletarSubServicio(event,ss,orden_servicio_id)

                            }else{
                                
                                ss.approved = false

                                Vue.swal({
                                    html: 'Ingrese el motivo para no agendar la siguiente cita',
                                    icon: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: "Aceptar",
                                    input: "select",
                                    inputPlaceholder: 'Seleccione un motivo',
                                    allowOutsideClick: false,                                
                                    inputOptions: this.opcionesNoAgedar,
                                    inputValidator: value => {

                                        return new Promise(resolve => {
                                            
                                            if (value == '') {

                                                resolve('Debe seleccionar el motivo')

                                            } else {

                                                resolve()

                                            }

                                        })

                                    }
                                })
                                .then(result => {

                                    if (result.isConfirmed) {

                                        //GUARDAR EL COMENTARIO EN LA CITA
                                        console.log('value',result.value)

                                        this.overlay = true

                                        this.setUrl('orden-servicio')
                                        
                                        this.requestApi({
                                            method: "POST",
                                            data: {
                                                typeStore: 'guardarComentarioCita',
                                                ordenServicioCitaId: ss.orden_servicio_cita_id,
                                                weMotivoId: result.value
                                            }
                                        })
                                        .then(res => {

                                            ss.approved = true

                                            this.overlay = true

                                            this.setUrl('orden-servicio')

                                            this.requestApi({
                                                method: "POST",
                                                data: {
                                                    ordenServicioCitaSubServicioId: ss.orden_servicio_cita_sub_servicio_id,
                                                    ordenServicioCitaId: ss.orden_servicio_cita_id,
                                                    ordenServicioId: orden_servicio_id,
                                                    comentario: result.value,
                                                    typeStore:'completarSubServicio'
                                                },
                                            }).then(res => {

                                                this.alertNotification({param: { html: res.data.res.msg }})
                                                
                                                this.getDataOrdenDeServicio()

                                                this.overlay = false

                                            }).catch(err => {

                                                this.overlay = false

                                            })

                                            this.alertNotification({
                                                param:{
                                                    html: res.data.res.msg
                                                }
                                            })

                                            this.overlay = false

                                        })
                                        .catch(() => {

                                            this.overlay = false
                                            ss.approved = false

                                        })

                                    }

                                })

                            }

                        })

                    }else{

                        this.accionCompletarSubServicio(event,ss,orden_servicio_id)

                    }

                }else{

                    if(ss.approved){

                        this.accionCompletarSubServicio(event,ss,orden_servicio_id)
                    }
                        
                }

            }           

        },

        accionCompletarSubServicio(event,ss,orden_servicio_id){

            Vue.swal({
                html: 'Está seguro completar el sub servicio?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                input: "textarea",
                inputPlaceholder: 'Escriba un comentario',
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.overlay = true

                    this.setUrl('orden-servicio')

                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioCitaSubServicioId: ss.orden_servicio_cita_sub_servicio_id,
                            ordenServicioCitaId: ss.orden_servicio_cita_id,
                            ordenServicioId: orden_servicio_id,
                            comentario: result.value,
                            typeStore:'completarSubServicio'
                        },
                    }).then(res => {

                        this.alertNotification({param: { html: res.data.res.msg }})
                        
                        this.getDataOrdenDeServicio()

                        this.overlay = false

                    }).catch(err => {

                        this.overlay = false

                    })

                }else{

                    this.closeDialogAgendarEvento()

                }

            })

        },

        viewDetalles(x){

            let div = document.getElementById(`div-detalles-orden_${x}`)

            console.log(div.style.height)

            if(Array.from(div.classList).includes('d-none')){

                div.classList.remove('d-none')
                this.iconBtn = 'mdi-eye-off'
                this.textBtn = 'OCULTAR'

            }else{

                div.classList.add('d-none')
                this.iconBtn = 'mdi-eye'
                this.textBtn = 'VER'

            }
 
        
        },
       
        onDrop(citas, oscId){

            console.log('onDrop' , oscId)

            if(oscId == null)
                return false

            if(this.subServicioIdCopiar == null){

                this.alertNotification({
                    param:{
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red',
                        html: 'Debe seleccionar un sub servicio antes de moverlo'
                    }
                })

                return false
            }

            let cita = citas.find(e => e.orden_servicio_cita_id == oscId)
            
            Vue.swal({
                html: 'Está seguro de agregar el sub servico en la cita seleccionada?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    let ss = {
                        orden_servicio_cita_id : oscId,
                        orden_servicio_cita_sub_servicio_id : this.ordenServicioCitaSubServicioIdCopiar,
                        sub_servicio_crm_id: this.subServicioIdCopiar
                    }

                    this.guardarSubServicio(ss, cita.orden_servicio_id, true)
                    .then(()=>{

                        this.ordenServicioCitaSubServicioIdCopiar = null
                        this.subServicioIdCopiar = null
                        this.oscIdTouch = null

                    })

                }else{

                    this.oscIdTouch = null

                }

            })
        },

        onDragstart(id, citas, css){
                console.log('onDragstart')
            this.ordenServicioCitaSubServicioIdCopiar = css.orden_servicio_cita_sub_servicio_id
            this.subServicioIdCopiar = css.sub_servicio_crm_id
            
            citas.filter(e => e.orden_servicio_cita_id != id)
            .forEach(e => { document.getElementById(e.orden_servicio_cita_id).style.display = 'flex' })

        },

        onDragend(ele, citas){

            citas.forEach(e => { document.getElementById(e.orden_servicio_cita_id).style.display = 'none' })

            if('ontouchstart' in document.documentElement)
                this.onDrop(citas, this.oscIdTouch)

        },

        touchmove(e){

            let divDrop = document.elementFromPoint(e.targetTouches[0].clientX,e.targetTouches[0].clientY)
            
            if(Array.from(divDrop.classList).includes('div-drop')){

                this.oscIdTouch = divDrop.id

            }else{

                this.oscIdTouch = null

            }            

        },

        buscarEvento(cita){
           
            this.overlay = true

            this.setUrl(`orden-servicio/${this.$route.params.quoteId}`)
            
            this.requestApi({
                method: "GET",
                data: { 
                    quoteId: this.$route.params.quoteId,
                    typeView: 'OBETENER_EVENTO_NO_ASIGNADO'
                },
            }).then(res => {

                console.log(res)
                
                this.eventosNoAsignados = res.data.eventos
                this.dialogEventoNoAsignado = true 
                this.asignaOrdenServicioCitaId = cita.orden_servicio_cita_id
                this.overlay = false

            }).catch(err => {

                this.overlay = false

            })

        },

        asignarEventoCita(evento){
        
            Vue.swal({
                html: 'Está seguro de asignar el evento a la cita?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true
                
                    this.setUrl('orden-servicio')

                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioCitaId: this.asignaOrdenServicioCitaId,
                            workEffortId: evento.work_effort_id,
                            typeStore: 'asignarEventoCita',
                            
                        },
                    }).then(res => {

                        try {
                            
                            this.getDataOrdenDeServicio()

                            this.alertNotification({
                                param:{
                                    html: res.data.res.msg
                                }
                            })

                            this.overlay = false
                            this.dialogEventoNoAsignado = false

                        } catch (error) {

                            this.overlay = false
                            console.log('error',error)

                        }

                    }).catch(err => {

                        this.overlay = false
                        reject()

                    })

                }

            })

        },

        cancelarOrden(ord){

            console.log('ord',ord)
            Vue.swal({
                html: 'Está seguro de cancelar la orden de servicio?, se cancelarán las citas y los eventos asignados a la misma.!',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                input: "textarea",
                inputPlaceholder: 'Escriba un comentario',
                ...this.paramAlertQuestion,
                inputValidator: value => {

                    return new Promise((resolve) => {

                        if (value == "") {
                            
                            resolve("Debe escribir un comentario para cancelar")

                        } else {

                            resolve()
                            
                        }

                    })

                }
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioId: ord.orden_servicio_id,
                            comentario: result.value,
                            typeStore:'cancelarOrden'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                       
                    }).catch(err => {

                        this.overlay = false

                    })

                }

            })


        },

        selectSubServicio(id, citas, css){

            console.log(id, citas, css)
            this.oscIdTouch = id
            this.ordenServicioCitaSubServicioIdCopiar = css.orden_servicio_cita_sub_servicio_id
            this.subServicioIdCopiar = css.sub_servicio_crm_id
            
            citas.filter(e => e.orden_servicio_cita_id != id)
            .forEach(e => { 

                let ele = document.getElementById(e.orden_servicio_cita_id)
                
                if(ele != null){

                    if(ele.style.display == 'flex'){

                        ele.style.display = 'none'

                    }else{

                        ele.style.display = 'flex'

                    }

                }
                
            })

        },

        selectSubServicioDestino(cita,citas){
            console.log('cita',cita)

            if(this.subServicioIdCopiar == null){

                this.alertNotification({
                    param:{
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red',
                        html: 'Debe seleccionar un sub servicio antes de moverlo'
                    }
                })

                return false
            }          
            
            Vue.swal({
                html: 'Está seguro de agregar el sub servico en la cita seleccionada?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    let ss = {
                        orden_servicio_cita_id : cita.orden_servicio_cita_id,
                        orden_servicio_cita_sub_servicio_id : this.ordenServicioCitaSubServicioIdCopiar,
                        sub_servicio_crm_id: this.subServicioIdCopiar
                    }

                    this.guardarSubServicio(ss, cita.orden_servicio_id, true)
                    .then(()=>{

                        this.ordenServicioCitaSubServicioIdCopiar = null
                        this.subServicioIdCopiar = null

                        this.selectSubServicio(cita.orden_servicio_id, citas, cita.sub_servicios)

                    })

                }

            })

        },

        openDialogNuevaOrden(ordenOriginal){

            this.setUrl(`orden-servicio/1`)

            const { 
                cliente_id, 
                servicio_id, 
                orden_servicio_id, 
                paciente_id, 
                paciente_nombres, 
                cliente_nombres,
                product_store_id,
                opportunity_id
            } = ordenOriginal

            this.partyIdClienteNuevaOrden = cliente_id
            this.originalServicioId = servicio_id
            this.originalOrdenServicioId = orden_servicio_id
            this.productStoreIdNuevaOrden = product_store_id
            this.pacienteIdNuevaOrden = paciente_id
            this.opportunityIdNuevaOrden = opportunity_id
          
            this.overlay = true

            this.requestApi({
                method: "GET",
                data: {                   
                    typeView: 'TIPOS_IDENTIFICACION_NUEVA_ORDEN'
                }
            }).then(res => {

                console.log('res',res)

                this.clienteNuevaOrden = cliente_nombres
                this.pacienteNuevaOrden = paciente_nombres

                this.dialogNuevaOrdenServicio = true

                this.servicios = res.data.detail.servicios
                this.partyIdentificationTypes = res.data.detail.tiposIdentificiacion
               
                this.overlay = false

            }).catch(err => {

                this.overlay = false

            })

        },

        addProducto(){

            this.productos.push({
                productos:[],
                search_product:null,
                productId:null,
                valoracion:false,
                requiere_valoracion:false,
                cantidad:1,
                uuid: Math.random().toString(36).substr(0, 20)
            })

        },

        deleteProducto(prod){

            this.productos = this.productos.filter(e => e.uuid != prod.uuid)

        },

        closeDialogNuevaOrdenServicio(){

            this.dialogNuevaOrdenServicio= false
            this.servicioSelectedId = null
            this.firstNamePaciente=null
            this.lastNamePaciente=null
            this.partyIdentificationTypeIdPaciente=null
            this.identificacionPaciente=null
            this.noValidaIdentificacion=false
            this.nuevoPaciente =false
            this.originalServicioId = null
            this.originalOrdenServicioId = null
            this.opportunityIdNuevaOrden = null
            this.searchPacienteList=''
            this.pacientes = []
            this.servicios = []

        },

        getPaciente(){

            this.setUrl(`orden-servicio/1`)

            this.overlay = true

            this.requestApi({
                method: "GET",
                data: {                   
                    typeView: 'OBETENER_PACIENTES_NUEVA_ORDEN',
                    paciente: this.searchPacienteList,
                },
            }).then(res => {

                console.log('res',res)           
                this.nuevoPaciente = !res.data.detail.length
                this.pacientes = res.data.detail              
            
                this.overlay = false

            }).catch(err => {

                this.overlay = false

            })

        },

        guardarNuevaOrden(){
           
            if(typeof this.$refs.form_nuevo_paciente != 'undefined' && !this.$refs.form_nuevo_paciente.validate())
                false
              
            Vue.swal({
                html: 'Está seguro de crear la orden de servicio?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {
               
                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    let prods = this.productos.map(obj => { 
  
                        return {
                            cantidad: obj.cantidad,
                            product_id : obj.productId.productId,
                            valoracion: obj.valoracion
                        }
                        
                    })

                    this.requestApi({
                        method: "POST",
                        data: {
                            typeStore:'crearNuevaOrdenServicio',
                            productos: prods,
                            firstName: this.firstNamePaciente,
                            lastName: this.lastNamePaciente,
                            partyIdentificationTypeId: this.partyIdentificationTypeIdPaciente,
                            partyIdCliente: this.partyIdClienteNuevaOrden,
                            identificacion: this.identificacionPaciente,
                            noValidaIdentificacion: this.noValidaIdentificacion,
                            nuevoPaciente: this.nuevoPaciente,            
                            productStoreId: this.productStoreIdNuevaOrden,   
                            opportunityId: this.opportunityIdNuevaOrden,           
                            partyIdPaciente: typeof this.partyIdPaciente == 'object' && this.partyIdPaciente != null ? this.partyIdPaciente.party_id : this.pacienteIdNuevaOrden
                        }
                    }).then(res => {

                        console.log('res',res)

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        window.open(`/genera-orden-servicio/${res.data.res.quoteId}`, "_blank")

                        this.closeDialogNuevaOrdenServicio()
                        this.overlay = false
                       
                       
                    }).catch(err => {

                        this.overlay = false

                    })
                    
                }

            })

        },

        getProductos(prod){
          
            this.setUrl("productos")

            this.requestApi({
                method: "GET",
                data: {
                    producto: prod.search_product,
                    bodegaId: this.productStoreIdNuevaOrden,
                    productStoreId: this.productStoreIdNuevaOrden,
                    partyId: this.partyIdClienteNuevaOrden,
                    state:'active'
                },
            }).then(res => {

                prod.productos = res.data._embedded.productos
                console.log(res.data._embedded.productos)
               
            })

        },

        setInputValoracion(prod){
         

            if(typeof prod.productId == 'object' &&  prod.productId != null){

                prod.requiere_valoracion = prod.productId.valoracion

            }else{

                prod.requiere_valoracion = false

            }
           
        },

        verFormulariosOrden(form){
            console.log('form',form)

            this.setUrl("orden-servicio/1")
            this.overlay = true

            this.requestApi({
                method: "GET",
                data: {
                    typeView: 'OBETENER_FORMULARIOS_ORDEN',
                    servicioCrmId: form.servicio_crm_id
                },
            }).then(res => {

                console.log('res',res)
                this.formularios = res.data.detail.formularios
                this.dialogFormulariosOrden = true
                this.overlay = false
                

            }).catch(err => {

                this.overlay = false
                console.log('err',err)

            })


        },

        closeDialogFormulario(){
  
            this.formularios=[]
            this.dialogFormulariosCita = false
            this.formIdEditar = null

        },

        seleccionarFormulario(form){

            console.log('form',form)
            this.formularioSeleccionado = form
            this.formularioServicioCrmIdSelected= form.formulario_servicio_crm_id
            this.dialogVisualizaFormulario=true
            this.nuevoFormulario=true

        },

        closeDialogVisualizaFormulario(){

            this.formularioSeleccionado = {}
            this.dialogVisualizaFormulario=false
            //this.ordenServicioCitaIdSelected = null
            //this.ordenServicioIdSelected = null
            this.formularioServicioCrmIdSelected = null
            this.nuevoFormulario=false
            this.editarFormulario = false
            this.formIdEditar = null

        },

        verFormulariosCita(cita){

            console.log('cita',cita)
            this.overlay = true

            this.citaSelected = cita
            this.ordenServicioCitaIdSelected = cita.orden_servicio_cita_id
            this.ordenServicioIdSelected = cita.orden_servicio_id
            this.servicioCrmIdSelected = cita.servicio_id
            this.nuevoFormulario= true
            this.editarFormulario = false

            this.setUrl("orden-servicio/1")

            this.requestApi({
                method: "GET",
                data: {
                    typeView: 'OBETENER_FORMULARIOS_CITA',
                    servicioCrmId: cita.servicio_id,
                    ordenServicioCitaId: cita.orden_servicio_cita_id,
                },
            }).then(res => {

                console.log('res',res)
                this.overlay = false
                this.formularios = res.data.formularios_nuevos
                this.formulariosGuardados = res.data.formularios_guardados
                this.dialogFormulariosCita = true
            
            }).catch(err => {

                this.overlay = false
                console.log('err',err)

            })

        },

        storeFormulario(formato){

            console.log('formato',formato,'formIdEditar', this.formIdEditar)
          //  return false        
            this.setUrl('orden-servicio')
            
            Vue.swal({
                html: 'Está seguro de guardar el formulario?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then((result) => {
               
                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                
                    this.requestApi({
                        method: "POST",
                        data: {
                            typeStore:'guardarFormularioCita',                        
                            ordenServicioCitaId: this.ordenServicioCitaIdSelected,
                            formularioServicioCrmId: this.formularioServicioCrmIdSelected,  
                            servicioCrmId: this.servicioCrmIdSelected,                       
                            subServicioCrmId:  this.formularioSeleccionado.sub_servicio_crm_id,
                            formIdEditar: this.formIdEditar,
                            formato: JSON.stringify(formato)
                        }
                    }).then(res => {

                        console.log('res',res)

                        this.verFormulariosCita(this.citaSelected)

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })
                        this.closeDialogVisualizaFormulario()
                        this.overlay = false
                          
                    }).catch(err => {

                        console.log('err',err)
                        this.overlay = false

                    })
                    
                }

            })

        },

        verFormularioGuardado(form, nuevo = false, editar = false){

            console.log('form',form)
            this.overlay=true
            this.nuevoFormulario = nuevo
            this.editarFormulario = editar
            this.formIdEditar = form.orden_servicio_cita_formulario_id
            this.formularioServicioCrmIdSelected = form.formulario_servicio_crm_id

            console.log('formato',JSON.parse(form.formato))
            this.formularioSeleccionado = {
                nombre: form.formulario,
                formato: form.formato
            }

            this.dialogVisualizaFormulario=true
            this.overlay= false
        },

        cancelarFormulario(form){

            this.setUrl('orden-servicio')
            
            Vue.swal({
                html: 'Está seguro de cancelar el formulario?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then((result) => {
               
                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                
                    this.requestApi({
                        method: "POST",
                        data: {
                            typeStore:'cancelarFormularioCita',                        
                            formularioServicioCrmId: form.orden_servicio_cita_formulario_id,  
                        }
                    }).then(res => {

                        console.log('res',res)

                        this.verFormulariosCita(this.citaSelected)

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })
                        this.closeDialogVisualizaFormulario()
                        this.overlay = false
                          
                    }).catch(err => {

                        console.log('err',err)
                        this.overlay = false

                    })
                    
                }

            })

        },

        verImagenesCita(cita){

            console.log('cita',cita)
            this.overlay = true

            this.citaSelected = cita
            this.ordenServicioCitaIdSelected = cita.orden_servicio_cita_id
            this.ordenServicioIdSelected = cita.orden_servicio_id
            this.servicioCrmIdSelected = cita.servicio_id
        
            this.setUrl("orden-servicio/1")

            this.requestApi({
                method: "GET",
                data: {
                    typeView: 'OBETENER_IMAGENES_CITA',
                    ordenServicioCitaId: cita.orden_servicio_cita_id
                },
            }).then(res => {

                console.log('res',res.data.detail)
                this.imagenesCargadas = res.data.detail.map(e => {

                    e.nombre = e.document.split('---')[1].split('.')[0]

                    return e

                })

                console.log(this.imagenesCargadas)
                this.overlay = false
            
            }).catch(err => {

                this.overlay = false
                console.log('err',err)

            })

        },

        setFile(event){

			this.files = typeof event != 'undefined' ? event : []

		},

        setFileIncidencia(event){
            this.filesIncidencia = typeof event != 'undefined' ? event : []
        },

        guardarImagenesCita(oscsId){

            this.overlay = true

			this.setUrl('orden-servicio')
			
			let formData = new FormData()

			this.files.map((file,x)=>{ 
				formData.append(x,file) 
			})

            formData.append('ordenServicioCitaId',this.ordenServicioCitaIdSelected)
			formData.append('tenantId',this.tenantId)
            formData.append('typeStore','storeImagenCita')
            formData.append('ordenServicioCitaSubServicioId',oscsId)

            formData.append('user',this.user.user_ligin_id)

            console.log(' this.url', this.url   )
			axios.post(
                this.url,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': 'Bearer '+ this.token
					}
				}
			).then(res=>{

				this.alertNotification({
					param:{html : res.data.res.msg}
				})
				
				this.overlay = false
                this.files = []
                this.verImagenesCita(this.citaSelected)
               
			}).catch(err =>{
                
				this.overlay = false

			    const {status} = err.response

				if(status === 500 || status === 422){

					let response = err.response

					this.errorRequest({
						data : {
							datos: response.data.errors,
							status : status,
							response: response
						}
					})

				}
 
			}).then(()=>{

				this.overlay = false

			})

        },

        eliminarImagenCita(ic){

            this.setUrl('orden-servicio')
            
            Vue.swal({
                html: 'Está seguro de eliminar la imagen de la cita?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then((result) => {
               
                if (result.isConfirmed) {
                    
                    this.overlay = true

                    this.setUrl('orden-servicio')
                
                    this.requestApi({
                        method: "POST",
                        data: {
                            typeStore:'eliminarImagenCita',                        
                            ordenServicioCitaMultiMediaId: ic.orden_servicio_cita_multi_media_id,  
                        }
                    }).then(res => {

                        console.log('res',res)

                        this.verImagenesCita(this.citaSelected)

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false
                          
                    }).catch(err => {

                        console.log('err',err)
                        this.overlay = false

                    })
                    
                }

            })

        },

        updateNombreArchivoMultimedia(oscm){

            this.overlay = true

            this.setUrl('orden-servicio')

            this.requestApi({
                method: "POST",
                data: {
                    typeStore:'cambiarNombreArchivoFormulario',                        
                    ordenServicioCitaMultiMediaId: oscm.orden_servicio_cita_multi_media_id,
                    nombre: oscm.nombre
                }
            }).then(res => {

                this.verImagenesCita(this.citaSelected)

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

                this.overlay = false
                
            }).catch(err => {

                console.log('err',err)
                this.overlay = false

            })

        },

        openDialogIncidencia(cita){

            this.citaSelected = cita
            this.dialogIncidencia = true

        },

        closeDialogIncidencia(){

            this.dialogIncidencia = false
            this.comentarioIncidencia = null
            this.filesIncidencia = []
            this.incidenciasCreadas = []

        },

        guardarIncidencia(){
     
            this.overlay = true

            this.setUrl('orden-servicio')

            let formData = new FormData()

            this.filesIncidencia.map((file,x)=>{ 
                formData.append(x,file) 
            })

            formData.append('ordenServicioCitaId', this.citaSelected.orden_servicio_cita_id)
            formData.append('tenantId',this.tenantId)
            formData.append('typeStore','storeIncidenciaCita')
            formData.append('descripcion',this.comentarioIncidencia)
            formData.append('user',this.user.user_ligin_id)

            axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+ this.token
                    }
                }
            ).then(res=>{

                this.alertNotification({
                    param:{html : res.data.res.msg}
                })
                
                this.overlay = false
                
                this.closeDialogIncidencia()
            
            }).catch(err =>{
                
                this.overlay = false

                const {status} = err.response

                if(status === 500 || status === 422){

                    let response = err.response

                    this.errorRequest({
                        data : {
                            datos: response.data.errors,
                            status : status,
                            response: response
                        }
                    })

                }

            }).then(()=>{

                this.overlay = false

            })

            console.log( this.citaSelected)
        },

        verIncidenciasCita(){

            console.log('cita',this.citaSelected)
            
            this.overlay = true

            this.setUrl("orden-servicio/1")

            this.requestApi({
                method: "GET",
                data: {
                    typeView: 'OBETENER_INCIDENCIAS_CITA',
                    ordenServicioCitaId: this.citaSelected.orden_servicio_cita_id
                },
            }).then(res => {

                this.incidenciasCreadas  = res.data.detail
                this.overlay = false
            
            }).catch(err => {

                this.overlay = false
                console.log('err',err)

            })

        },

        closeDialogFormulariosOrden(){

            this.dialogFormulariosOrden = false
            this.formularios = []


        },

        completarOrden(ord){

            Vue.swal({
                html: 'Está seguro de completar la orden de servicio?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioId: ord.orden_servicio_id,
                            typeStore:'completarOrden'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                       
                    }).catch(err => {

                        this.overlay = false

                    })

                }

            })

        },

        completarCita(cita){
        
            Vue.swal({
                html: `Está seguro de completar la ${cita.descripcion}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true

                    this.setUrl('orden-servicio')
                    
                    this.requestApi({
                        method: "POST",
                        data: {
                            ordenServicioCitaId: cita.orden_servicio_cita_id,
                            typeStore:'completarCita'
                        },
                    }).then(res => {

                        this.getDataOrdenDeServicio()

                        this.alertNotification({
                            param:{
                                html: res.data.res.msg
                            }
                        })

                        this.overlay = false

                       
                    }).catch(err => {

                        this.overlay = false

                    })

                }

            })

        },

        habilitarEdicionNombreCita(ord, cita){

            let indexCita = ord.citas.indexOf(cita)

            let indexOrden = this.ordenes.indexOf(ord)

            ord.citas[indexCita].editar_nombre_cita = true

            Vue.set(this.ordenes,indexOrden,ord)

        },

        actualizarNombreCita(cita){

            this.overlay = true

            this.setUrl('orden-servicio')
            
            this.requestApi({
                method: "POST",
                data: {
                    ordenServicioCitaId: cita.orden_servicio_cita_id,
                    descripcion: cita.descripcion,
                    typeStore:'actualizarNombreCita'
                },
            }).then(res => {

                this.getDataOrdenDeServicio()

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

                this.overlay = false

                
            }).catch(err => {

                this.overlay = false

            })



        }

    },
    created(){

        this.getDataOrdenDeServicio()
        console.log('params', this.$route.params)

    },

}

</script>

<style>
    .v-expansion-panel-content__wrap{
        padding: 0!important;
    }

    .w-100{

        width: 100%;
    }

    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }

    .select-sub-servicio  div.v-select{
        height: 40px;
    }

    .select-sub-servicio div.v-input__prepend-outer{
        margin: 0!important;
    }

    .select-sub-servicio .v-text-field.v-text-field--solo .v-input__control {
        min-height: 38px;
        padding: 0;
    }

    .select-sub-servicio  .v-select__selection{
        white-space: normal
    }

    .v-input{

        align-items: center!important;
    }

    .div-drop{

        display: flex;
        align-items: center;
        justify-content: center;
        background: #efefefd9;
        position: absolute;
        height: 93%;
        width: 97%;
        z-index: 9;
        font-weight: bold;
    
    }

    .valoracion {
        border: 1px solid #9e9e9e;
        padding: 6.8px 7px;
        border-radius: 5px;
    }

    .swal2-select{

        border: 1px solid silver;
        border-radius: 5px;

    }

    .btn-upload-img .v-input__append-outer{

        margin-top: 0px!important;

    }

    .border-img{

        border: 3px solid silver

    }
    .seccion-img .v-list-item{
        padding:0px
    }
</style>